import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const SecondaryHeader = styled.h2`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  line-height: 53px;
  letter-spacing: 0.19px;
  color: ${(props) => props.theme.golden};
  margin: auto;
  width: 90%;
  text-align: center;
  padding-bottom: 20px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.15px;
  }
`
export const Row = styled.div`
  display: none;
`

export const Line = styled.hr`
  border: solid 1.5px #ecebdd;
`

export const ContentContainer = styled.div`
  max-width: 780px;
  margin: 0px auto 40px;
  .yotpo-date-format,
  .yotpo-reviewer-name,
  .yotpo-read-more-text {
    font-family: 'FilsonProBook' !important;
    color: ${(props) => props.theme.grayDark} !important;
  }
  .yotpo-review-title {
    font-family: 'FilsonPro' !important;
    color: ${(props) => props.theme.grayDark} !important;
  }
  @media (max-width: ${breakpoints.l}px) {
    max-width: 90%;
  }
`
export const Anchor = styled.div`
  outline: none;
`
