import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'
import YotpoReviews from '../YotpoReviews'
import YotpoStars from '../YotpoStars'
import { Row, SecondaryHeader, ContentContainer } from './styles'
import { IS_FOREIGN_COUNTRY } from '../../utils/constants'

const ReviewsSection = ({ link, page, productId, name }) => (
  <ContentContainer>
    {IS_FOREIGN_COUNTRY ? (
      <>
        <div
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="5763bccae0a06d08e809ecbb"
          data-businessunit-id="5fd2f3360b2b9d0001bfc661"
          data-style-height="700px"
          data-style-width="100%"
          data-theme="light"
          data-sku="100-FRM, 150-FRA, 150EA, SKY-201, 100CA, 100DC, 100DB, 100DF, SKY-203, SKY-202, 100DE, SKY-002, 100DD, SKY-V3, CAL-V3, SKY-009, SKY-004,"
          data-review-languages="en"
          data-no-reviews="hide"
          data-fullwidth="true"
        >
          <a
            href="https://www.trustpilot.com/review/skylightframe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </>
    ) : (
      <>
        <SecondaryHeader>
          <Trans>Customer Reviews</Trans>
        </SecondaryHeader>
        {page === 'plus' ? (
          <Row>
            <YotpoStars color="#fdc36d" textColor="#444444" />
          </Row>
        ) : null}
        <YotpoReviews link={link} productId={productId} name={name} />
      </>
    )}
  </ContentContainer>
)

ReviewsSection.propTypes = {
  page: PropTypes.string,
  link: PropTypes.string,
  productId: PropTypes.string,
  name: PropTypes.string,
}
export default ReviewsSection
