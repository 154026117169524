import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withTranslation } from 'gatsby-plugin-react-i18next'

import BasicVsPlus from 'src/components/BasicVsPlus'
import SEO from 'src/components/seo'
import Analytics from 'src/utils/Analytics'
import FAQs from 'src/components/FAQs'
import PlusFeatures from '../../components/PlusFeatures'
import ReviewsSection from '../../components/ReviewsSection'
import { Anchor } from '../../components/ReviewsSection/styles'

class PlusProductPage extends React.Component {
  componentDidMount() {
    const {
      data: { plusProduct },
    } = this.props
    Analytics.track(
      `Viewed ${Analytics.trackingConstants[plusProduct.handle] || plusProduct.handle} Product Page`
    )
  }

  render() {
    const { data, t } = this.props
    const product = data.plusProduct

    return (
      <>
        <SEO
          title={t(product.title)}
          description={t(
            'Send Videos and Captions to your Digital Photo Frame. Save your photos with cloud backup and share using our intuitive mobile app!'
          )}
          path={`/products/${product.handle}/`}
          imagePath={product.images[0].gatsbyImageData.images.fallback.src}
        />
        <BasicVsPlus product={product} />
        <PlusFeatures title={t('Upgrade to access these popular features!')} />
        <FAQs page="plus" />
        <Anchor id="review" />
        <ReviewsSection page="plus" productId="7073751498804" name="The Skylight Frame" />
      </>
    )
  }
}

PlusProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func,
}

export const query = graphql`
  query ($handle: String!, $language: String!) {
    plusProduct: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      variants {
        id
        title
        price
        shopifyId
      }
      images {
        id
        gatsbyImageData
      }
    }
    locales: allLocale(filter: { ns: { in: ["common", "plus"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withTranslation()(PlusProductPage)
